import React, { useState } from 'react'
import {
    FormGuideAccordionButton,
    FormGuideAccordionGrid,
    FormGuideWrapper,
    StyledTitle,
    StyledPercentageBar,
    StyledPercentageWrapper,
} from './FormGuide.styled'
import { ChevronUp } from '../Icons/ChevronUp'
import { MatchHistoryBadges } from '../MatchHistoryBadges/MatchHistoryBadges'
import { VisuallyHidden } from '../VisuallyHidden'
import { DisplayTeam, SportName } from '@the-game/api-interfaces'
import { Box } from '../Box'
import MarginHistoryBadges from '../MarginHistoryBadges'

export interface FormGuideProps {
    sport: SportName
    homeTeam: DisplayTeam
    awayTeam: DisplayTeam
    venue: string | undefined
}

export function FormGuide({
    sport,
    homeTeam,
    awayTeam,
    venue,
}: FormGuideProps) {
    const [open, setOpen] = useState(false)

    return (
        <Box>
            <FormGuideAccordionButton
                aria-expanded={open}
                aria-controls={`form-guide-${homeTeam.team}-${awayTeam.team}`}
                onClick={() => setOpen(!open)}
                type="button"
            >
                {open ? 'Hide' : 'View'} form guide
                <ChevronUp
                    themeColor="black"
                    title=""
                    role="presentation"
                    rotate={open ? 0 : 180}
                />
            </FormGuideAccordionButton>
            <FormGuideWrapper
                toggled={open}
                id={`form-guide-${homeTeam.team}-${awayTeam.team}`}
            >
                <VisuallyHidden component="li">
                    {!homeTeam.ladderPosition || !awayTeam.ladderPosition
                        ? `ladder positions not available yet`
                        : `ladder position, home team, ${homeTeam.team}, ${homeTeam.ladderPosition}
                        , away team, ${awayTeam.team}, ${awayTeam.ladderPosition}`}
                </VisuallyHidden>
                <FormGuideAccordionGrid aria-hidden="true">
                    <span className="data">
                        {homeTeam.ladderPosition ?? '--'}
                    </span>

                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="title"
                        href={`/${sport}/ladder`}
                    >
                        Ladder position
                    </a>
                    <span className="data">
                        {awayTeam.ladderPosition ?? '--'}
                    </span>
                </FormGuideAccordionGrid>

                <FormGuideAccordionGrid>
                    <span className="data">
                        {homeTeam.percentageTipped !== undefined &&
                        homeTeam.percentageTipped !== null
                            ? `${homeTeam.percentageTipped.toFixed(1)}%`
                            : '--'}
                    </span>
                    <span className="title">What other people tipped</span>
                    <span className="data">
                        {awayTeam.percentageTipped !== undefined &&
                        awayTeam.percentageTipped !== null
                            ? `${awayTeam.percentageTipped.toFixed(1)}%`
                            : '--'}
                    </span>
                    {homeTeam.percentageTipped !== undefined &&
                        awayTeam.percentageTipped !== null && (
                            <StyledPercentageWrapper>
                                <StyledPercentageBar
                                    percentage={homeTeam.percentageTipped}
                                />
                            </StyledPercentageWrapper>
                        )}
                </FormGuideAccordionGrid>

                {(!homeTeam.winHistory ||
                    homeTeam.winHistory?.length === 0 ||
                    !awayTeam.winHistory ||
                    awayTeam.winHistory?.length === 0) && (
                    <VisuallyHidden component="li">
                        History of last 5 matches is not available yet
                    </VisuallyHidden>
                )}
                <FormGuideAccordionGrid>
                    {homeTeam.winHistory && homeTeam.winHistory.length > 0 ? (
                        <>
                            <VisuallyHidden>
                                last 5 matches for {homeTeam.team}
                            </VisuallyHidden>
                            <MatchHistoryBadges
                                size="sm"
                                showLatestText={true}
                                winHistory={homeTeam.winHistory}
                            />
                        </>
                    ) : (
                        <StyledTitle aria-hidden="true">N/A</StyledTitle>
                    )}
                    <span>Last 5</span>
                    {awayTeam.winHistory && awayTeam.winHistory.length > 0 ? (
                        <>
                            <VisuallyHidden>
                                last 5 matches for {awayTeam.team}
                            </VisuallyHidden>
                            <MatchHistoryBadges
                                size="sm"
                                showLatestText={true}
                                winHistory={awayTeam.winHistory}
                            />
                        </>
                    ) : (
                        <StyledTitle aria-hidden="true">N/A</StyledTitle>
                    )}
                </FormGuideAccordionGrid>

                {sport === 'Cricket' && (
                    <>
                        {(!homeTeam.lastFiveSixes ||
                            homeTeam.lastFiveSixes?.length === 0 ||
                            !awayTeam.lastFiveSixes ||
                            awayTeam.lastFiveSixes?.length === 0) && (
                            <VisuallyHidden component="li">
                                Sixes scored in last 5 matches is not available
                                yet
                            </VisuallyHidden>
                        )}
                        <FormGuideAccordionGrid>
                            {homeTeam.lastFiveSixes &&
                            homeTeam.lastFiveSixes.length > 0 ? (
                                <>
                                    <VisuallyHidden>
                                        sixes scored in last 5 matches for{' '}
                                        {homeTeam.team}
                                    </VisuallyHidden>
                                    <MarginHistoryBadges
                                        size="sm"
                                        showLatestText={true}
                                        marginHistory={homeTeam.lastFiveSixes}
                                    />
                                </>
                            ) : (
                                <StyledTitle aria-hidden="true">
                                    N/A
                                </StyledTitle>
                            )}
                            <span>
                                Sixes
                                <br /> Last 5
                            </span>
                            {awayTeam.lastFiveSixes &&
                            awayTeam.lastFiveSixes.length > 0 ? (
                                <>
                                    <VisuallyHidden>
                                        sixes scored in last 5 matches for{' '}
                                        {awayTeam.team}
                                    </VisuallyHidden>
                                    <MarginHistoryBadges
                                        size="sm"
                                        showLatestText={true}
                                        marginHistory={awayTeam.lastFiveSixes}
                                    />
                                </>
                            ) : (
                                <StyledTitle aria-hidden="true">
                                    N/A
                                </StyledTitle>
                            )}
                        </FormGuideAccordionGrid>
                    </>
                )}

                <VisuallyHidden component="li">
                    {!homeTeam.winAtVenue ||
                    !homeTeam.lossAtVenue ||
                    !awayTeam.winAtVenue ||
                    !awayTeam.lossAtVenue ||
                    !venue
                        ? `win/loss is not available yet`
                        : `${homeTeam.team}, win/loss at ${venue}, ${homeTeam.winAtVenue}/
                        ${homeTeam.lossAtVenue}. ${awayTeam.team}, win/loss at ${venue},
                        ${awayTeam.winAtVenue}/${awayTeam.lossAtVenue}`}
                </VisuallyHidden>
                <FormGuideAccordionGrid aria-hidden="true">
                    <span className="data">
                        {homeTeam.winAtVenue ?? '--'}/
                        {homeTeam.lossAtVenue ?? '--'}
                    </span>
                    <span className="title">
                        Win/loss at venue
                        <br />
                        {venue ?? '--'}
                    </span>
                    <span className="data">
                        {awayTeam.winAtVenue ?? '--'}/
                        {awayTeam.lossAtVenue ?? '--'}
                    </span>
                </FormGuideAccordionGrid>
                <VisuallyHidden component="li">
                    {!homeTeam.seasonWin || !awayTeam.seasonWin
                        ? 'season win is not available yet'
                        : `season win, home team, ${homeTeam.team}, ${homeTeam.seasonWin}. away
                    team, ${awayTeam.team}, ${awayTeam.seasonWin}.`}
                </VisuallyHidden>

                <FormGuideAccordionGrid aria-hidden="true">
                    <span className="data">
                        {homeTeam.seasonWin && homeTeam.seasonPlayed
                            ? `${(
                                  (homeTeam.seasonWin / homeTeam.seasonPlayed) *
                                  100
                              ).toFixed(1)}%`
                            : homeTeam.seasonPlayed
                            ? '0%'
                            : '--'}
                    </span>
                    <span className="title">Season win %</span>
                    <span className="data">
                        {awayTeam.seasonWin && awayTeam.seasonPlayed
                            ? `${(
                                  (awayTeam.seasonWin / awayTeam.seasonPlayed) *
                                  100
                              ).toFixed(1)}%`
                            : awayTeam.seasonPlayed
                            ? '0%'
                            : '--'}
                    </span>
                </FormGuideAccordionGrid>
            </FormGuideWrapper>
        </Box>
    )
}
